import React, {useState} from 'react';
import AuthServices from '@mp/mp-auth-services';
import LoadingMask from '@mp/mp-loading-mask';
import {createUseStyles} from 'react-jss';
import clsx from 'clsx';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { Box, Tooltip } from '@mui/material';
import background from "./img/front.png";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Fab from '@mui/material/Fab';

const useStyles = createUseStyles(() => ({
    loginroot: {
        display: 'flex'
        ,flexWrap: 'wrap'
        ,padding: 20
        ,margin: 4
        ,width: '30ch'
        ,flexDirection: 'column'
        ,alignItems: 'center'
        ,position: "relative"
    }
    ,loginimagen: {
        maxWidth: '100%'
        ,width: '70%'
        ,minHeight: '100vh'
        ,backgroundImage: `url(${background})`
        ,backgroundRepeat: 'no-repeat'
        ,backgroundSize: 'auto 100%'
    }
    ,backdrop: {
        zIndex: 99999
        ,color: '#fff'
        ,position: "absolute"
    }
    ,title: {
        paddingRight: 10
        ,paddingLeft: 10
    }
    ,logininput: {
        marginLeft: 5
        ,flex: 1
    }
    ,logindivider: {
        height: 28
        ,margin: 4
    }
    ,logindividerh: {
        margin: 10
        ,minWidth: '50%'
    }
    ,loginmargin: {
        margin: 5
    }
    ,loginWithoutLabel: {
        marginTop: 15
    }
    ,loginTextField: {
        width: '25ch'
    }
}));

const Login = props => {

    const classes = useStyles();

    const [token, setToken] = React.useState(AuthServices.getWithExpiry('token'));
    const [open, setOpen] = React.useState(false);
    const [msgAuth, setMsgAuth] = useState(null);
    const [user, setUser] = useState(localStorage.getItem('user') ?? '');
    const [password, setPassword] = useState(localStorage.getItem('password') ?? '');
    const [checked, setChecked] = React.useState(localStorage.getItem('recordar')==='true' ? true : false);
    
    React.useEffect(() => {
        if (token){
            dispatchEvent(new CustomEvent(
                "@mp/app-login/auth", {detail:{auth: true, token}}
            ));
        }
        else{
            dispatchEvent(new CustomEvent(
                "@mp/app-login/logout", {detail:{auth: false, token}}
            ));
        }
    }, [token])

    const handleChangeCheck = (event) => {
        setChecked(event.target.checked);
        localStorage.setItem('recordar', event.target.checked);

        if (event.target.checked){
            localStorage.setItem('user', user);
            localStorage.setItem('password', password);
        }
        else{
            localStorage.removeItem("user")
            localStorage.removeItem("password")
            localStorage.removeItem("token")
        }
    };

    const handleChangeUser = (event) => {
        setUser(event.target.value);
        if (checked) localStorage.setItem('user', event.target.value ?? '');
    };

    const handleChangePassword = (event) => {
        setPassword(event.target.value);
        if (checked) localStorage.setItem('password', event.target.value ?? '');
    };

    const changeAuth = (auth, token) =>{
        setToken(token);
    }

    const autenticar = () => {

        setMsgAuth('Autenticando, espere un momento..');
        setOpen(true);
        const credentials = {
            "email": user
            ,"password": password
        }
        AuthServices.auth(props.baseUri + '/auth/login', credentials).then((data) => {

            const auth = data.access_token ? true : false;
            
            setMsgAuth(auth ? '' : 'Autenticación fallida');
            if (checked) {
                AuthServices.setWithExpiry('token', data.access_token, 1000 * 60 * 60 * 8)
            }

            changeAuth(auth, data.access_token);

            setOpen(false)
        })
        .catch((error) => {
           
            localStorage.removeItem('token');
            if (error.status === 401){
                setMsgAuth("Autenticación fallida"); 
            }
            else{
                setMsgAuth(error.status + ' ' + error.message); 
            }    
            
            AuthServices.setWithExpiry('token', 'sfddgfd', 1000 * 60 * 60 * 8);
            changeAuth(true, 'sfddgfd');
            //changeAuth(false, null);
            setOpen(false)
        });
    }

    const resetToken = () => {
        localStorage.removeItem("token");
        setToken(null);
    }

    const BotonLogout = () => {
        return (
            <Tooltip
                title="Cerrar sesión" 
                placement="top" 
                arrow
            >
                <Fab 
                    className={classes.fab}
                    style={{
                        position: 'absolute'
                        ,bottom: '18px'
                        ,right: '24px'
                        ,zIndex: 99999
                    }}
                    variant="extended" 
                    size="small" 
                    aria-label="add" 
                    onClick={resetToken} 
                >
                    <ExitToAppIcon />
                </Fab>
            </Tooltip>
        )
    }
    
    return ( token ? <BotonLogout/> :
        <Box style={{
            backgroundColor: "#f8f8f8"
            ,minHeight: "100vh"
            ,display: "flex"
            ,flexDirection: "row"
            ,alignItems: "center"
            ,color: "rgb(14, 11, 11)"
            ,flexWrap:'nowrap'
          }}>
            <Paper className={classes.loginimagen}></Paper>
            <Paper component="form" className={classes.loginroot}>
                <LoadingMask open={open} loadingText="Autenticando, espere un momento.."/>
                <h4>Acceso {props.AppName}</h4>
                <TextField
                    label="Usuario"
                    id="user"
                    onChange={handleChangeUser}
                    className={clsx(classes.loginmargin, classes.loginTextField)}
                    variant="outlined"
                    style={{width:'25ch'}}
                    value={user}
                    margin="normal"
                />
                <TextField
                    label="Contraseña"
                    id="password"
                    type="password"
                    onChange={handleChangePassword}
                    variant="outlined"
                    style={{width:'25ch'}}
                    value={password}
                    className={clsx(classes.loginmargin, classes.loginTextField, classes.loginWithoutLabel)}
                    margin="normal"
                />
                <Divider className={classes.logindividerh} orientation="horizontal" />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={checked}
                            onChange={handleChangeCheck}
                            name="recordar"
                            color="primary"
                        />
                    }
                    label="Recordar"
                />
                <Button variant="contained" color="primary" onClick={autenticar}>
                    Ingresar
                </Button>
                <h5>{msgAuth}</h5>
            </Paper>
        </Box>
    )
}

Login.defaultProps = {
    baseUri: "http://localhost:8005/api"
}

export default Login;